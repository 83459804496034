import { Flex, Text } from "@mantine/core";
import { cloneElement, ReactElement, ReactNode } from "react";

interface IIconTextProps {
	icon: ReactNode;
	text: string;
	color?: string;
}

export default function IconText({ icon, text, color = "var(--body)" }: IIconTextProps) {
	const formattedIcon = cloneElement(icon as ReactElement<any>, {
		color: color,
	});

	return (
		<Flex align="center" gap={8}>
			{formattedIcon}
			<Text size={16} color={color}>
				{text}
			</Text>
		</Flex>
	);
}
