import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

type ParamInput =
	| URLSearchParams
	| Record<string, string | null>
	| ((prevParams: Record<string, string>) => Record<string, string | null>);

type Options = {
	merge?: boolean;
};

export function useSearchParams() {
	const { search, pathname } = useLocation();
	const history = useHistory();

	// Create a memoized URLSearchParams from the current search string
	const params = useMemo(() => new URLSearchParams(search), [search]);

	/**
	 * Sets search parameters and updates the URL.
	 *
	 * Accepts:
	 *   - `URLSearchParams` directly
	 *   - An object of key/value pairs (string | null)
	 *   - A function returning an object of key/value pairs, receiving the current params as an object
	 *
	 * Options:
	 *   - `{ merge: true }` will merge the new parameters with existing ones
	 */
	const setSearchParams = (newParams: ParamInput, options: Options = {}) => {
		// Decide whether or not to start with the existing params
		const baseParams = new URLSearchParams(options.merge ? search : "");

		// Convert to a plain object so we can manipulate easily
		const currentParamsObj = Object.fromEntries(baseParams.entries());

		let resolvedParams: URLSearchParams | Record<string, string | null>;

		// If newParams is a function, call it with the "previous" params object
		if (typeof newParams === "function") {
			resolvedParams = newParams(currentParamsObj);
		} else {
			resolvedParams = newParams;
		}

		// If the resolved params is a URLSearchParams instance, use it directly
		if (resolvedParams instanceof URLSearchParams) {
			history.push(`${pathname}?${resolvedParams.toString()}`);
			return;
		}

		// Otherwise, iterate over the object and set/delete keys in baseParams
		Object.entries(resolvedParams).forEach(([key, value]) => {
			if (value === null) {
				baseParams.delete(key);
			} else {
				baseParams.set(key, value);
			}
		});

		// Finally, push the updated params to history
		history.push(`${pathname}?${baseParams.toString()}`);
	};

	/**
	 * Removes one or multiple parameters from the query string.
	 */
	const removeParam = (paramNames: string | string[]) => {
		const updatedParams = new URLSearchParams(search);
		const paramsToRemove = Array.isArray(paramNames) ? paramNames : [paramNames];

		paramsToRemove.forEach((p) => updatedParams.delete(p));

		history.push(`${pathname}?${updatedParams.toString()}`);
	};

	return [params, setSearchParams, removeParam] as const;
}
