import { Stack, Text } from "@mantine/core";
import { CSSProperties } from "react";

interface IDataKpiProps {
	value: string;
	label: string;
	withBorderRight?: boolean;
	px?: string;
	w?: string;
	styles?: {
		value?: CSSProperties,
		label?: CSSProperties
	}
}

export default function DataKpi({ label, value, withBorderRight, px, w, styles }: IDataKpiProps) {
	return (
		<Stack
			px={px}
			w={w}
			style={{
				borderRight: withBorderRight ? "1px solid var(--divider40)" : "none",
			}}
			align="center"
			spacing={4}
		>
			<Text size={20} fw={700} color="var(--heading)" style={styles?.value}>
				{value}
			</Text>
			<Text size={16} color="var(--bodyLight)" style={styles?.label}>
				{label}
			</Text>
		</Stack>
	);
}
