import { Tooltip } from "@mantine/core";
import { FloatingPosition } from "@mantine/core/lib/Floating";
import { ReactNode } from "react";

interface ICustomTooltipProps {
	children: ReactNode;
	label: string | ReactNode;
	multiline?: boolean;
	withArrow?: boolean;
	width?: number | "auto";
	position?: FloatingPosition;
	isDisabled?: boolean;
	zIndex?: number;
	withinProtal?: boolean;
}

export default function CustomTooltip({
	children,
	label,
	multiline,
	width,
	withArrow = true,
	position = "top-start",
	isDisabled,
	zIndex,
	withinProtal,
}: ICustomTooltipProps) {
	return (
		<Tooltip
			withinPortal={withinProtal}
			zIndex={zIndex}
			disabled={isDisabled}
			events={{ touch: true, hover: true, focus: true }}
			position={position}
			color="white"
			style={{
				color: "var(--heading)",
				boxShadow: "1px 2px 12px 0 rgba(0, 0, 0, 0.08)",
			}}
			p={12}
			radius={8}
			openDelay={300}
			width={width}
			withArrow={withArrow}
			multiline={multiline}
			label={label}
		>
			<div>{children}</div>
		</Tooltip>
	);
}
