import { ICarpetOrder } from "../interfaces/IOrder";

export enum EOrderType {
	IN_HOUSE = "in-house",
	ROUTE = "route",
	PICKUP_POINT = "pickup-point",
}

const returnOrderType = (t: any, order: ICarpetOrder) => {
	let tempData = {
		value: "in-house",
		label: t("general.inHouse"),
	};
	if (order.isPickupPoint) {
		tempData = {
			value: EOrderType.PICKUP_POINT,
			label: order.pickupPointName || "",
		};
	} else if (order.delivery && !order.isPickupPoint) {
		tempData = {
			value: EOrderType.ROUTE,
			label: t("general.route"),
		};
	} else if (!order.delivery && !order.isPickupPoint) {
		tempData = {
			value: EOrderType.IN_HOUSE,
			label: t("general.inHouse"),
		};
	}

	return tempData;
};

export default returnOrderType;
