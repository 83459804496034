import { useGetOneClient } from "@hooks/clients/useGetOneClient";
import useUpdateClient from "@hooks/clients/useUpdateClient";
import { ActionIcon, Button, Loader, Modal, TextInput, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import AddressGoogleInputEdit from "@reusable/AddressGoogleInputEdit";
import googleAPI from "@services/google";
import { IconEdit } from "@tabler/icons";
import { AddressDetailed } from "@views/clothes/ClothesTypes";
import React, { useEffect, useState } from "react";
import { geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";
import { useTranslation } from "react-i18next";
import { actionIconStyles } from "../v2/readyForDelivery/components/ComponentsStyle";

interface ClientEditDetailsProps {
	clientId: string;
	dataChangedWithSuccess?: () => void;
}

export interface ClientUpdate {
	name: string;
	phone: string;
	address: string;
	addressDetailed: AddressDetailed;
	reperPoint?: string;
	clientId?: string;
	comments?: string;
	email?: string
}

export function ClientEditDetails({ clientId, dataChangedWithSuccess }: ClientEditDetailsProps) {
	const [opened, { open, close }] = useDisclosure(false);
	const [localClient, setLocalClient] = useState<ClientUpdate>({
		name: "",
		phone: "",
		address: "",
		addressDetailed: {},
		reperPoint: "",
		comments: "",
		email: ""
	});
	const { t } = useTranslation();
	const { data, isLoading: isLoadingCLient, refetch } = useGetOneClient(clientId, true);
	const { mutate, isLoading: isLoadingMutate, isSuccess } = useUpdateClient();

	useEffect(() => {
		if (data && data.clientInfo) {
			setLocalClient({
				name: data.clientInfo.name || "",
				phone: data.clientInfo.phone || "",
				address: data.clientInfo.address || "",
				addressDetailed: data.clientInfo.addressDetailed || {},
				reperPoint: data.clientInfo.reperPoint || "",
				comments: data.clientInfo.comments || "",
				email: data.clientInfo.email || "",
			});
		}
		//eslint-disable-next-line
	}, [data]);

	useEffect(() => {
		if (isSuccess) {
			refetch();
			dataChangedWithSuccess && dataChangedWithSuccess();
		}
		//eslint-disable-next-line
	}, [isSuccess]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof ClientUpdate) => {
		if (e.currentTarget.value != null) {
			setLocalClient({
				...localClient,
				[field]: e.currentTarget?.value || "",
			});
		}
	};

	const handleUpdate = () => {
		mutate({ ...localClient, clientId });
		// Implement additional logic if needed
	};
	const saveButtonDisabled = () => {
		if (localClient.name?.length < 1 || localClient.phone?.length !== 10) {
			return true;
		}
		// if localClient is the same as data.clientInfo
		if (
			localClient.name === data?.clientInfo?.name &&
			localClient.phone === data?.clientInfo?.phone &&
			localClient.address === data?.clientInfo?.address &&
			localClient.reperPoint === data?.clientInfo?.reperPoint
			&& localClient.comments === data?.clientInfo?.comments
			&& localClient.email === data?.clientInfo?.email
		) {
			return true;
		}
		return false;
	};
	const setOrUnsetAddress = (obj: { label: string; value: any }) => {
		if (obj.value) {
			geocodeByPlaceId(obj.value.place_id)
				.then((results) => {
					const extracted = googleAPI.transformAddressObject(results[0].address_components);
					getLatLng(results[0]).then((results) => {
						setLocalClient({
							...localClient,
							addressDetailed: {
								...extracted,
								lat: results.lat.toString(),
								lng: results.lng.toString(),
							},
							address: obj.label,
						});
					});
					// setClientAddress(results[0].formatted_address);
					//@ts-ignore
				})
				.catch((error) => console.error(error));
		}
	};

	return (
		<>
			<Modal
				opened={opened}
				onClose={close}
				title={t("general.editClientDetails")}
				zIndex={9999999}
				size="lg"
			>
				{isLoadingCLient && <Loader />}
				{!isLoadingCLient && (
					<div style={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "1rem" }}>
						<TextInput
							label={t("general.clientName")}
							value={localClient?.name || ""}
							onChange={(e) => handleInputChange(e, "name")}
							error={localClient.name?.length < 1 ? t("carpetNewOrder.errors.clientName") : false}
							onErrorCapture={() => console.log("error")}
						/>
						<TextInput
							type="number"
							label={t("general.clientPhone")}
							value={localClient.phone || ""}
							error={
								localClient.phone?.length !== 10
									? t("carpetNewOrder.errors.clientPhoneInvalid")
									: false
							}
							onChange={(e) => {
								if (e.currentTarget.value.length <= 10) handleInputChange(e, "phone");
							}}
						/>
						<TextInput
							label={"Email"}
							value={localClient.email || ""}
							onChange={(e) => handleInputChange(e, "email")}
						/>
						<TextInput
							label={t("general.reperPoint")}
							value={localClient.reperPoint || ""}
							onChange={(e) => handleInputChange(e, "reperPoint")}
						/>
						<TextInput
							label={t("general.clientAddress")}
							value={localClient.address || ""}
							onChange={(e) => handleInputChange(e, "address")}
						/>
						<AddressGoogleInputEdit
							keyOfInput="address-start-1"
							key="address-start-1"
							label={t("general.clientAddressGoogleMaps")}
							setValue={setOrUnsetAddress}
							addressFromClient={{
								addressText: localClient?.address || "",
								addressDetailed: localClient?.addressDetailed || undefined,
							}}
						/>
						<TextInput
							label={"Comentarii"}
							value={localClient?.comments || ""}
							onChange={(e) => handleInputChange(e, "comments")}
						/>

						<Button
							variant="filled"
							onClick={handleUpdate}
							color="green.7"
							fullWidth
							disabled={saveButtonDisabled()}
							loading={isLoadingMutate}
						>
							{t("products.buttonSaveEdits")}
						</Button>

						<Button
							variant="filled"
							onClick={close}
							fullWidth
							color="red.7"
							loading={isLoadingMutate}
						>
							{t("carpetNewOrder.successCloseModal")}
						</Button>
					</div>
				)}
			</Modal>
			<Tooltip label={t("general.editClientDetails")} position="left">
				<ActionIcon size={actionIconStyles.size} onClick={open}>
					<IconEdit color="var(--accent)" />
				</ActionIcon>
			</Tooltip>
		</>
	);
}
