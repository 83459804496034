import { Flex, Text } from "@mantine/core";
import { IconBulb } from "@tabler/icons";

interface ITipProps {
	tip: string;
	tipCount?: string;
	tipColor?: string;
}

export default function Tip({ tip, tipCount, tipColor = "var(--error)" }: ITipProps) {
	return (
		<Flex gap={4}>
			<IconBulb style={{ flexShrink: 0 }} stroke={2} size={20} color={tipColor} />
			<Text color={tipColor} size={14}>
				<Text component="span" color={tipColor} fw={500} size={14}>
					Tip{tipCount}:
				</Text>{" "}
				{tip}
			</Text>
		</Flex>
	);
}
