import { Button, createStyles, Flex, Stack } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconMail, IconMapPin, IconMessageCircle, IconPhone } from "@tabler/icons";
import localeNumber from "@views/horeca/v2/companyList/utils/localeNumber";
import {
	calculateTotalCancel,
	ClientInterface,
} from "@views/orders/SmallerComponents/ClientDetailsDrawer";
import TitleAndDescription from "@views/smsNotifications/components/TitleAndDescription";
import moment from "moment";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationInterface } from "types";
import DeleteClientModal from "../../clients/components/DeleteClientModal";
import { ICarpetOrder } from "../../ordersHistory/interfaces/IOrder";
import DataKpi from "./DataKpi";
import EditClientModal from "./EditClientModal";
import IconText from "./IconText";

const useStyles = createStyles(() => ({
	kpiGrid: {
		width: "100%",
		display: "grid",
		gridTemplateColumns: "repeat(auto-fit, minmax(min(8em, 100%), 1fr))",
		gap: "16px",
	},
}));

interface IDetailsSectionProps {
	clientInfo: ClientInterface;
	orders: ICarpetOrder[];
	organization: OrganizationInterface;
	isSuperAdmin?: boolean;
	refetch: () => void;
}

export default function DetailsSection({
	clientInfo,
	orders,
	organization,
	isSuperAdmin,
	refetch,
}: IDetailsSectionProps) {
	const { t, i18n } = useTranslation();
	moment.locale(i18n.language);

	const matchesXs = useMediaQuery("(max-width: 586px)");
	const { classes } = useStyles();
	const createdDate = `${moment(clientInfo.createdAt).format("ddd, DD MMM YYYY, HH:mm")} (${t(
		"clientsV2.clientsDrawer.modified"
	).toLowerCase()} ${moment(clientInfo.updatedAt).fromNow()})`;

	const data = useCallback(() => {
		return orders?.reduce(
			(acc, order) => {
				const newTotal = order?.restToMinimumOrder ? order?.minimumOrder : order.totalPrice;
				acc.ordersCount += 1;
				acc.ordersTotal += newTotal ?? 0;
				// @ts-ignore
				acc.ordersCanceled += calculateTotalCancel(order);

				return acc;
			},
			{
				ordersCount: 0,
				ordersTotal: 0,
				ordersCanceled: 0,
			}
		);
	}, [orders]);

	return (
		<Stack w="100%" spacing={16}>
			<Stack spacing={16}>
				<TitleAndDescription
					gap={0}
					styles={{
						title: {
							fontSize: 24,
							fontWeight: 600,
						},
					}}
					title={clientInfo.name}
					description={createdDate}
				/>
				<IconText icon={<IconPhone />} text={clientInfo.phone} />
				<IconText icon={<IconMail />} text={clientInfo.email || "-"} />
				<IconText icon={<IconMapPin />} text={clientInfo.address || "-"} />
				<IconText icon={<IconMessageCircle />} text={clientInfo.comments || "-"} />
			</Stack>
			<div
				className={classes.kpiGrid}
				style={{
					...(matchesXs && { gridTemplateColumns: "1fr" }),
				}}
			>
				<DataKpi
					styles={{
						value: {
							fontSize: matchesXs ? 32 : 20,
						},
					}}
					label={t("clientsV2.clientsDrawer.orders")}
					value={data().ordersCount.toString() || "0"}
				/>
				<DataKpi
					styles={{
						value: {
							fontSize: matchesXs ? 32 : 20,
						},
					}}
					label={t("clientsV2.clientsDrawer.ordersTotal")}
					value={localeNumber({
						value: data().ordersTotal ?? 0,
						currency: organization.organizationCurrency,
						locale: i18n.language,
						style: "currency",
					})}
				/>
				<DataKpi
					styles={{
						value: {
							fontSize: matchesXs ? 32 : 20,
						},
					}}
					label={t("clientsV2.clientsDrawer.ordersCanceled")}
					value={data().ordersCanceled.toString() || "0"}
				/>
			</div>
			<Flex align="center" justify="center" w="100%" gap={16}>
				<EditClientModal data={clientInfo} organization={organization} refetch={refetch} />
				<DeleteClientModal
					id={clientInfo._id || ""}
					refetch={refetch}
					customButton={
						<Button
							color="red"
							classNames={{
								root: "button-style",
							}}
							variant="outline"
						>
							{t("general.buttonDelete")}
						</Button>
					}
					disabled={!isSuperAdmin || orders.length > 0}
					withTooltip={!isSuperAdmin || orders.length > 0}
					tooltipMessage={t("clientsV2.clientsDrawer.deleteClientButtonTooltip")}
				/>
			</Flex>
		</Stack>
	);
}
