import { useGetOneClient } from "@hooks/clients/useGetOneClient";
import { ActionIcon, createStyles, Modal, Stack, Text, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconUser } from "@tabler/icons";
import { ICampaignInterface } from "@views/clients/PromotionList";
import { ClientInterface } from "@views/orders/SmallerComponents/ClientDetailsDrawer";
import { cloneElement, CSSProperties, ReactElement, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { OrganizationInterface } from "types";
import DotsLoader from "../ordersHistory/components/DotsLoader";
import { ICarpetOrder } from "../ordersHistory/interfaces/IOrder";
import { actionIconStyles } from "../readyForDelivery/components/ComponentsStyle";
import CampaignsSection from "./clientsDrawerV2/CampaignsSection";
import DetailsSection from "./clientsDrawerV2/DetailsSection";
import OrdersSection from "./clientsDrawerV2/OrdersSection";

const useStyles = createStyles(() => ({
	modal: {
		borderRadius: 20,
		height: "calc(100vh - 48px)",
		alignSelf: "center",
		maxWidth: "500px",
		scrollbarWidth: "thin",
	},
	inner: {
		justifyContent: "flex-end",
		overflow: "hidden",
	},
}));

interface IClientDrawerData {
	clientInfo: ClientInterface;
	orderList: ICarpetOrder[];
	campaignList: ICampaignInterface[];
	phone: string;
}

interface IClientsDrawerV2Props {
	clientId: string;
	customButton?: ReactNode;
	isDisabled?: boolean;
	tooltipMessage?: string;
	isTooltipAlwaysOpened?: boolean;
	tooltipStyles?: CSSProperties;
}

export default function ClientsDrawerV2({
	clientId,
	customButton,
	isDisabled,
	tooltipMessage,
	isTooltipAlwaysOpened,
	tooltipStyles,
}: IClientsDrawerV2Props) {
	const { t } = useTranslation();
	const { classes } = useStyles();
	const [isOpened, { open, close }] = useDisclosure(false);
	const [client, setClient] = useState<IClientDrawerData>();
	const { data, isLoading, isSuccess, refetch } = useGetOneClient(clientId, isOpened);

	//@ts-ignore
	const userData = useSelector((state) => state.form.userData);

	const organizationSelected = useSelector<OrganizationInterface>(
		//@ts-ignore
		(state) => state.form.organizationSelected
	) as OrganizationInterface;

	useEffect(() => {
		if (data && isSuccess && isOpened) {
			setClient(data);
		}
	}, [data, isSuccess]);

	const customButtonComponent =
		customButton &&
		cloneElement(customButton as ReactElement<any>, {
			onClick: () => open(),
			disabled: isDisabled,
		});

	return (
		<>
			<Modal
				title={t("clientsV2.clientsDrawer.title")}
				size="lg"
				zIndex={2000}
				classNames={{
					modal: classes.modal,
					inner: classes.inner,
				}}
				opened={isOpened}
				onClose={close}
				overflow="inside"
				transition="slide-left"
			>
				<DotsLoader isLoading={isLoading}>
					{client && isSuccess ? (
						<Stack spacing={24}>
							<DetailsSection
								isSuperAdmin={userData.isSuperAdmin}
								refetch={refetch}
								clientInfo={client?.clientInfo}
								orders={client?.orderList}
								organization={organizationSelected}
							/>
							<CampaignsSection campaigns={client?.campaignList || []} />
							<OrdersSection organization={organizationSelected} orders={client?.orderList || []} />
						</Stack>
					) : (
						<Text size={16} color="var(--bodyLight)">
							{t("clientsV2.clientsDrawer.clientNotFound")}
						</Text>
					)}
				</DotsLoader>
			</Modal>
			<Tooltip
				style={tooltipStyles}
				opened={isTooltipAlwaysOpened}
				label={tooltipMessage || t("general.clientDetails")}
				withArrow
			>
				{customButton && customButtonComponent ? (
					customButtonComponent
				) : (
					<ActionIcon
						onClick={open}
						disabled={isDisabled}
						size={actionIconStyles.size}
						variant="subtle"
					>
						<IconUser color="var(--accent)" />
					</ActionIcon>
				)}
			</Tooltip>
		</>
	);
}
