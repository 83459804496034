import { Badge, Text, Tooltip } from "@mantine/core";
import { useTranslation } from "react-i18next";

const badgeStyles = (t: any) => {
	return [
		{
			color: "#C04040",
			label: t("general.route"),
			value: "route",
		},
		{
			color: "#50C7F2",
			label: t("general.inHouse"),
			value: "in-house",
		},
		{
			color: "#e9a708",
			label: t("general.pickUpPoint"),
			value: "pickup-point",
		},
	];
};

interface IOrderTypeBadgeProps {
	orderType: "route" | "in-house" | "pickup-point";
	pickpuPointName?: string;
}

export default function OrderTypeBadge({ orderType, pickpuPointName }: IOrderTypeBadgeProps) {
	const { t } = useTranslation();

	const returnType = badgeStyles(t).find((item) => item.value === orderType);

	return (
		<Tooltip withArrow disabled={orderType !== "pickup-point"} label={pickpuPointName}>
			<Badge
				leftSection={
					<div
						style={{
							borderRadius: "1000px",
							width: 12,
							height: 12,
							backgroundColor: returnType?.color || "var(--heading)",
						}}
					></div>
				}
				bg="none"
				px="10px"
				py="14px"
				radius={8}
				style={{
					border: "1px solid var(--divider)",
					fontSize: 14,
					textTransform: "none",
					fontWeight: 500,
					color: "var(--heading)",
				}}
			>
				<Text
				// className="ellipsis-overflow"
				// style={{
				// 	maxWidth: "80px",
				// }}
				>
					{orderType === "pickup-point" ? pickpuPointName : returnType?.label || "N/A"}
				</Text>
			</Badge>
		</Tooltip>
	);
}
