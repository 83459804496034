import { useGoogleMapsInit } from "@hooks/functional/useGoogleMapsInit";
import { ActionIcon, Flex, Text, TextInput } from "@mantine/core";
import { AddressDetailed } from "@views/clothes/ClothesTypes";
import { actionIconStyles } from "@views/orders/v2/readyForDelivery/components/ComponentsStyle";
import { CSSProperties, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Pencil } from "tabler-icons-react";

function AddressGoogleInputEdit({
	setValue,
	addressFromClient,
	label,
	keyOfInput,
	style,
}: {
	keyOfInput?: string;
	label?: string;
	setValue: ({ label, value }: { label: string; value: any }) => void;
	addressFromClient: {
		addressText: string;
		addressDetailed: AddressDetailed | undefined;
	};
	style?: CSSProperties;
}) {
	const { i18n } = useTranslation();
	//@ts-ignore
	const organizationSelected = useSelector((state) => state.form.organizationSelected);
	const { isLoaded } = useGoogleMapsInit();
	const [editAddress, setEditAddress] = useState<boolean>(false);

	return (
		<div
			style={{
				width: "100%",
			}}
		>
			<Text weight={500} style={{ marginBottom: "10px" }}>
				{label || "Adresa"}
			</Text>
			{isLoaded &&
				(addressFromClient?.addressText && !editAddress ? (
					<Flex align="flex-end" gap={8} w="100%">
						<TextInput
							styles={{
								// @ts-ignore
								input: { ...style },
							}}
							value={addressFromClient?.addressText}
							disabled
							style={{
								width: "100%",
							}}
						/>
						<ActionIcon
							radius={8}
							size={actionIconStyles.size}
							onClick={() => {
								setEditAddress(true);
							}}
						>
							{" "}
							<Pencil color="var(--accent)" />
						</ActionIcon>
					</Flex>
				) : (
					<GooglePlacesAutocomplete
						key={keyOfInput}
						apiKey={process.env.REACT_APP_GOOGLE_API}
						debounce={300}
						minLengthAutocomplete={3}
						apiOptions={{
							language: i18n.language || "ro",
							region: organizationSelected.organizationCountry || "ro",
						}}
						selectProps={{
							// value: "",
							onChange: (value: any) => {
								setValue(value);
								setEditAddress(false);
							},
						}}
						autocompletionRequest={{
							componentRestrictions: {
								country: [organizationSelected.organizationCountry] || "ro",
							},
						}}
					/>
				))}
		</div>
	);
}

export default AddressGoogleInputEdit;
