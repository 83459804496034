import { Stack, Text } from "@mantine/core";
import { CSSProperties, ReactNode } from "react";

interface ITitleAndDescriptionProps {
	title: string;
	description: string | ReactNode;
	reverse?: boolean;
	descriptionSize?: number;
	gap?: number;
	styles?: {
		title?: CSSProperties;
		description?: CSSProperties;
	};
}

export default function TitleAndDescription({
	title,
	description,
	reverse,
	descriptionSize = 14,
	gap = 4,
	styles,
}: ITitleAndDescriptionProps) {
	return reverse ? (
		<Stack spacing={gap}>
			<Text style={styles?.title} size={14} color="var(--bodyLight)">
				{title}
			</Text>
			{typeof description === "string" ? (
				<Text style={styles?.description} size={descriptionSize} color="var(--body)">
					{description}
				</Text>
			) : (
				<div>{description}</div>
			)}
		</Stack>
	) : (
		<Stack spacing={gap}>
			<Text style={styles?.title} size={14} color="var(--body)">
				{title}
			</Text>
			{typeof description === "string" ? (
				<Text style={styles?.description} size={14} color="var(--bodyLight)">
					{description}
				</Text>
			) : (
				<div>{description}</div>
			)}
		</Stack>
	);
}
