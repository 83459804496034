import { hideNotification } from "@mantine/notifications";
import {
	showNotificationError,
	showNotificationLoading,
	showNotificationSuccess,
} from "@reusable/Notifications/notificationSystem";
import clientsApi from "@services/clients";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export default function useDeleteClient() {
	const { t } = useTranslation();
	const randomId = Math.random().toString(36).substring(7);

	return useMutation((id: string) => clientsApi.deleteClient(id), {
		retry: false,
		onSuccess: async () => {
			showNotificationSuccess(t("Success"));
			hideNotification(randomId);
		},
		onError: async (error: string) => {
			showNotificationError(t("Error"));
			hideNotification(randomId);
		},
		onMutate: async () => {
			showNotificationLoading(t("Loading..."), randomId);
		},
	});
}
