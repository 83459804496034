interface IlocaleNumberProps {
	style?: "currency" | "percent" | "decimal" | "unit";
	value: number;
	locale?: string;
	currency?: string;
}

export default function localeNumber({
	style = "decimal",
	value,
	locale = "ro",
	currency = "RON",
}: IlocaleNumberProps) {
	//   if (style === "currency" && !currency)
	//     throw new Error("Currency style requires a currency argument.");

	return value?.toLocaleString(locale, {
		style,
		currency,
	});
}
