import { Avatar, Badge, Flex, Text } from "@mantine/core";
import { IconColorSwatch } from "@tabler/icons";
import CustomTooltip from "@views/smsNotifications/components/CustomTooltip";

interface ITicketNumberBadgeProps {
	ticketNumber: string | number;
	color?: string;
	secondaryTickets?: string[] | number[];
	withoutHoverCard?: boolean;
}

export default function TicketNumberBadge({
	ticketNumber,
	color = "var(--heading)",
	secondaryTickets,
	withoutHoverCard,
}: ITicketNumberBadgeProps) {
	const allTickets = [...(secondaryTickets || []), ticketNumber?.toString()];

	return (
		<CustomTooltip
			withinProtal
			zIndex={2000}
			isDisabled={!secondaryTickets?.length || withoutHoverCard}
			width={200}
			label={
				<Flex w="100%" justify="center" gap={8} wrap="wrap">
					{allTickets?.map((item, i) => (
						<CustomTicketNumberBadge color={color} key={i} ticketNumber={item?.toString()} />
					))}
				</Flex>
			}
		>
			<Flex align="center" gap={4}>
				<CustomTicketNumberBadge
					color={color}
					tickets={allTickets as string[]}
					ticketNumber={allTickets?.join(", ") || ""}
				/>
				{/* {secondaryTickets?.length ? `+${secondaryTickets.length}` : null} */}
			</Flex>
		</CustomTooltip>
	);
}

interface ICustomTicketNumberBadgeProps {
	ticketNumber: string;
	color: string;
	tickets?: string[];
}

export function CustomTicketNumberBadge({
	ticketNumber,
	color,
	tickets,
}: ICustomTicketNumberBadgeProps) {
	return (
		<Badge
			size="xl"
			h="fit-content"
			leftSection={
				<Avatar
					radius={0}
					variant="outline"
					size={20}
					styles={{
						placeholder: {
							border: "none",
							padding: 2,
							backgroundColor: "var(--divider20)",
							borderRadius: 2,
						},
					}}
				>
					<IconColorSwatch
						size={20}
						style={{
							flexShrink: 0,
						}}
						color={color}
					/>
				</Avatar>
			}
			bg="none"
			px="10px"
			py="4px"
			radius={8}
			style={{
				border: "1px solid var(--divider)",
				fontSize: 14,
				textTransform: "none",
				fontWeight: 500,
				color: "var(--heading)",
			}}
		>
			{tickets?.length ? (
				<Flex wrap="wrap">
					{tickets.map((item, i) => (
						<Text
							key={i}
							style={{
								whiteSpace: "nowrap",
							}}
							mr={2}
							size={14}
							color="var(--heading)"
						>
							{item}
							{i !== tickets?.length - 1 && `, `}
						</Text>
					))}
				</Flex>
			) : (
				<Text maw={200} size={14} color="var(--heading)">
					{ticketNumber}
				</Text>
			)}
		</Badge>
	);
}
