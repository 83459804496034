import useDeleteClient from "@hooks/clients/useDeleteClient";
import { ActionIcon, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconTrash } from "@tabler/icons";
import WarningModal from "@views/pages/settingsV2/components/WarningModal";
import { cloneElement, ReactElement, ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { actionIconStyles } from "../../readyForDelivery/components/ComponentsStyle";

interface IDeleteClientModalProps {
	id: string;
	refetch: () => void;
	disabled?: boolean;
	customButton?: ReactNode;
	withTooltip?: boolean;
	tooltipMessage?: string;
}

export default function DeleteClientModal({
	id,
	refetch,
	disabled,
	customButton,
	withTooltip,
	tooltipMessage,
}: IDeleteClientModalProps) {
	const { t } = useTranslation();
	const [isOpened, { open, close }] = useDisclosure(false);
	const { mutate: deleteClient, isSuccess: isDeleteSuccess } = useDeleteClient();

	const customButtonComponent =
		customButton &&
		cloneElement(customButton as ReactElement<any>, {
			onClick: () => open(),
			disabled,
		});

	const handleDeleteClient = () => {
		if (id) {
			deleteClient(id);
		}
	};

	useEffect(() => {
		if (isDeleteSuccess) {
			refetch();
			close();
		}
	}, [isDeleteSuccess]);

	return (
		<>
			<WarningModal
				description={t("clientsV2.deleteModal.description")}
				isOpened={isOpened}
				onCancel={() => close()}
				onConfirm={() => handleDeleteClient()}
			/>
			<Tooltip
				disabled={!withTooltip}
				label={tooltipMessage || t("general.buttonDelete")}
				withArrow
			>
				<div>
					{customButton && customButtonComponent ? (
						customButtonComponent
					) : (
						<ActionIcon
							size={actionIconStyles.size}
							onClick={open}
							variant="subtle"
							disabled={disabled}
						>
							<IconTrash color={disabled ? "gray" : "var(--accent)"} />
						</ActionIcon>
					)}
				</div>
			</Tooltip>
		</>
	);
}
