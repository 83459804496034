import "core-js";
import React from "react";
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { icons } from "./assets/icons";
import "./index.css";
import "./polyfill";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./storeConfig";

//@ts-ignore
React.icons = icons;

function TutorialWrapper() {
	return (
		<App />
	);
}
const { store, persistor } = configureStore();
ReactDOM.render(
	//@ts-ignore
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<TutorialWrapper />
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
