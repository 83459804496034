import { createStyles, Flex, Text } from "@mantine/core";
import { CSSProperties, ReactNode } from "react";

interface ICreateStylesParams {
	withoutBorder?: boolean;
}

const useStyles = createStyles((theme, { withoutBorder }: ICreateStylesParams) => ({
	row: {
		paddingBlock: 8,
		paddingInline: 24,
		borderBottom: withoutBorder ? " none" : "1px solid var(--divider40)",
	},
}));

interface IRowTitleAndDescripionProps {
	title: string;
	description: string | ReactNode;
	withoutBorder?: boolean;
	unstyled?: boolean;
	styles?: {
		title?: CSSProperties;
		description?: CSSProperties;
		container?: CSSProperties;
	};
	hidden?: boolean;
}

export default function RowTitleAndDescripion({
	description,
	title,
	withoutBorder,
	unstyled,
	styles,
	hidden,
}: IRowTitleAndDescripionProps) {
	const { classes } = useStyles({ withoutBorder });
	return (
		<Flex
			hidden={hidden}
			align={"center"}
			wrap="wrap"
			justify={"space-between"}
			className={unstyled ? "" : classes.row}
			style={styles?.container}
		>
			<Text style={styles?.title} size={14} color="var(--bodyLight)">
				{title}
			</Text>
			{typeof description === "string" ? (
				<Text style={styles?.description} size={14} color="var(--body)">
					{description}
				</Text>
			) : (
				<div>{description}</div>
			)}
		</Flex>
	);
}
