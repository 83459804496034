import useUpdateClient from "@hooks/clients/useUpdateClient";
import { Button, Flex, Modal, Stack, Textarea, TextInput, Tooltip } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { ClientInterface } from "@views/orders/SmallerComponents/ClientDetailsDrawer";
import OfferAddressInput from "@views/pages/register/components/offerComponents/OfferAddressInput";
import checkObjectsDifference from "@views/pages/settingsV2/form/checkObjectDifference";
import { cloneElement, ReactElement, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationInterface } from "types";

interface IEditClientModalProps {
	organization: OrganizationInterface;
	data: ClientInterface;
	refetch: () => void;
	customButton?: ReactNode;
	withoutTooltip?: boolean;
	tooltipMessage?: string;
	withDisabledComment?: boolean;
	onSave?: (values: any) => void;
}

const defaultValues: Partial<ClientInterface> = {
	name: "",
	phone: "",
	address: "",
	email: "",
	reperPoint: "",
	comments: "",
};

export default function EditClientModal({
	data,
	organization,
	refetch,
	customButton,
	tooltipMessage,
	withoutTooltip = true,
	withDisabledComment,
	onSave,
}: IEditClientModalProps) {
	const { t } = useTranslation();
	const matchesXs = useMediaQuery("(max-width: 586px)");
	const matches = useMediaQuery("(max-width: 768px)");
	const [isOpened, { open, close }] = useDisclosure(false);
	const [dataToEdit, setDataToEdit] = useState<Partial<ClientInterface>>(defaultValues);
	const { mutate: updateClient, isSuccess } = useUpdateClient();

	const customButtonComponent =
		customButton &&
		cloneElement(customButton as ReactElement<any>, {
			onClick: () => open(),
		});

	const changeValue = (field: keyof ClientInterface, val: any) => {
		setDataToEdit((prev) => ({
			...prev,
			[field]: val,
		}));
	};

	const clientData = {
		address: data.address,
		addressDetailed: data.addressDetailed,
		name: data.name,
		phone: data.phone,
		comments: data.comments,
		email: data.email,
		reperPoint: data.reperPoint,
	};

	useEffect(() => {
		if (data && isOpened) {
			setDataToEdit(clientData);
		}
	}, [data, isOpened]);

	const { dataToUpdateDiff, diffKeysArray } = checkObjectsDifference({ ...clientData }, dataToEdit);

	const submitChanges = () => {
		if (diffKeysArray.length) {
			if (onSave) {
				onSave({
					...dataToUpdateDiff,
					clientId: data._id,
				});
			} else {
				// @ts-ignore
				updateClient({
					...dataToUpdateDiff,
					clientId: data._id,
				});
			}
		}
	};

	const closeAndReset = () => {
		setDataToEdit(defaultValues);
		close();
	};

	useEffect(() => {
		if (isSuccess) {
			refetch();
			closeAndReset();
		}
	}, [isSuccess]);

	return (
		<>
			<Modal
				title={t("clientsV2.clientsDrawer.editClient")}
				styles={{
					modal: {
						width: "100%",
						maxWidth: "500px",
					},
					inner: {
						paddingInline: matchesXs ? 0 : 16,
					},
				}}
				zIndex={2000}
				radius={20}
				opened={isOpened}
				onClose={closeAndReset}
				centered
			>
				<Stack spacing={16}>
					<TextInput
						onChange={(e) => changeValue("name", e.target.value)}
						value={dataToEdit.name}
						label={t("ordersTable.clientName")}
						classNames={{
							input: "input-styles",
						}}
					/>
					<TextInput
						onChange={(e) => changeValue("phone", e.target.value)}
						value={dataToEdit.phone}
						label={t("ordersTable.editOrderV2.phone")}
						classNames={{
							input: "input-styles",
						}}
					/>
					<TextInput
						onChange={(e) => changeValue("email", e.target.value)}
						value={dataToEdit.email}
						label="Email"
						classNames={{
							input: "input-styles",
						}}
					/>
					{organization.hasGoogleMapsActive ? (
						<OfferAddressInput
							label={t("ordersTable.clientAddress")}
							onChange={(simple, detailed) =>
								setDataToEdit((prev) => ({
									...prev,
									address: simple,
									addressDetailed: detailed,
								}))
							}
							value={dataToEdit.address || ""}
						/>
					) : (
						<TextInput
							onChange={(e) => {
								changeValue("address", e.target.value);
								changeValue("addressDetailed", "");
							}}
							value={dataToEdit.address}
							label={t("ordersTable.clientAddress")}
							classNames={{
								input: "input-styles",
							}}
						/>
					)}
					<TextInput
						onChange={(e) => changeValue("reperPoint", e.target.value)}
						value={dataToEdit.reperPoint}
						label={t("general.reperPoint")}
						classNames={{
							input: "input-styles",
						}}
					/>
					<Textarea
						disabled={withDisabledComment}
						onChange={(e) => changeValue("comments", e.target.value)}
						value={dataToEdit.comments}
						classNames={{
							input: "input-styles",
						}}
						label={t("ordersTable.measureDrawer.comments")}
						autosize
						minRows={4}
					/>
					<Flex
						wrap={matches ? "wrap" : "nowrap"}
						align="center"
						justify="flex-end"
						gap={16}
						w="100%"
					>
						<Button
							onClick={closeAndReset}
							type="button"
							w={matches ? "100%" : "auto"}
							classNames={{
								root: "button-style",
							}}
							color="dark"
							variant="subtle"
						>
							{t("registry.buttonNo")}
						</Button>

						<Button
							onClick={submitChanges}
							disabled={!diffKeysArray.length}
							type="button"
							w={matches ? "100%" : "auto"}
							classNames={{
								root: "button-style",
							}}
							color="yellow"
						>
							{t("general.buttonSave")}
						</Button>
					</Flex>
				</Stack>
			</Modal>
			<Tooltip
				withArrow
				disabled={withoutTooltip}
				label={tooltipMessage || t("clientsV2.clientsDrawer.editClient")}
			>
				{customButton && customButtonComponent ? (
					customButtonComponent
				) : (
					<Button
						onClick={open}
						color="dark"
						classNames={{
							root: "button-style",
						}}
						variant="outline"
					>
						{t("general.buttonEdit")}
					</Button>
				)}
			</Tooltip>
		</>
	);
}
