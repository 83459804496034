import { Badge, MantineSize } from "@mantine/core";
import utils from "@reusable/utils";
import { useTranslation } from "react-i18next";
import { ICarpetOrder } from "../../ordersHistory/interfaces/IOrder";

interface IOrderStatusBadgeProps {
	order: ICarpetOrder;
	size?: MantineSize;
}

export default function OrderStatusBadge({ order, size = "sm" }: IOrderStatusBadgeProps) {
	const { t } = useTranslation();
	return (
		<Badge
			color="orange"
			size={size}
			variant="light"
			style={{
				wordBreak: "break-word",
				height: "auto",
			}}
			styles={{
				inner: {
					whiteSpace: "normal",
				},
			}}
		>
			{utils.statusDecriptorForTable(
				t,
				order.status,
				order.delivery,
				order.pickUpDateAproximate,
				order.pickUpDate
			)}
		</Badge>
	);
}
