import { Stack, Text } from "@mantine/core";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ICarpetOrder } from "../../ordersHistory/interfaces/IOrder";
import OrderCard from "./OrderCard";
import { OrganizationInterface } from "types";

interface IOrdersSectionProps {
	orders: ICarpetOrder[];
	organization: OrganizationInterface
}

export default function OrdersSection({ orders, organization }: IOrdersSectionProps) {
	const { t, i18n } = useTranslation();
	moment.locale(i18n.language);

	return (
		<Stack spacing={16}>
			<Text size={20} fw={600} color="var(--heading)">
				{t("clientsV2.clientsDrawer.ordersHistory")}
			</Text>
			{orders?.length ? (
				orders?.map((order, i) => <OrderCard organization={organization} order={order} key={i} />)
			) : (
				<Text ta="center" size={16} color="var(--bodyLight)">
					{t("clientsV2.clientsDrawer.noOrdersFound")}
				</Text>
			)}
		</Stack>
	);
}
