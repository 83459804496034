import { Flex, Text, UnstyledButton } from "@mantine/core";
import { SpotlightActionProps } from "@mantine/spotlight";
import { IconX } from "@tabler/icons";
import TicketNumberBadge from "@views/horeca/v2/companyList/components/TicketNumberBadge";
import TitleAndDescription from "@views/smsNotifications/components/TitleAndDescription";
import { useTranslation } from "react-i18next";
import OrderStatusBadge from "./clientsDrawerV2/OrderStatusBadge";

export default function SearchComponentItemV2({
	action,
	styles,
	classNames,
	hovered,
	onTrigger,
	...others
}: SpotlightActionProps) {
	const { t } = useTranslation();
	
	return action.id === "close" ? (
		<UnstyledButton
			w="100%"
			className="simple-hover"
			p={16}
			data-hovered={hovered || undefined}
			tabIndex={-1}
			// @ts-ignore
			onMouseDown={(e) => e.stopPropagation()}
			onClick={onTrigger}
			{...others}
		>
			<Flex align="center" gap={8}>
				<IconX size={18} />
				<Text>{t("general.layoutV2.closeSearch")}</Text>
			</Flex>
		</UnstyledButton>
	) : (
		<UnstyledButton
			style={{
				borderRadius: 8,
			}}
			w="100%"
			className="simple-hover"
			p={16}
			data-hovered={hovered || undefined}
			tabIndex={-1}
			// @ts-ignore
			onMouseDown={(e) => e.stopPropagation()}
			onClick={onTrigger}
			{...others}
		>
			<Flex align="center" justify="space-between" gap={16}>
				<TitleAndDescription title={action.client} description={action.description || "-"} />
				<SearchRegistryColorBadge
					ticketNumber={action.ticketNumber}
					secondaryTickets={action.secondaryTickets}
					registerColor={action.registerColor}
				/>
			</Flex>
			<OrderStatusBadge size="md" order={action.order} />
		</UnstyledButton>
	);
}

interface ISearchRegistryColorBadgeProps {
	ticketNumber: number;
	secondaryTickets?: string[];
	registerColor: string;
}

export function SearchRegistryColorBadge({
	ticketNumber,
	secondaryTickets,
	registerColor,
}: ISearchRegistryColorBadgeProps) {
	return (
		<TicketNumberBadge
			withoutHoverCard
			ticketNumber={ticketNumber}
			color={registerColor}
			secondaryTickets={secondaryTickets}
		/>
	);
}
