import { Stack, Text } from "@mantine/core";
import googleAPI from "@services/google";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { OrganizationInterface } from "types";

interface IOrganizationData {
	onChange: (simple: string, detailed: any) => void;
	value: string;
	label?: string;
	required?: boolean;
	error?: string | null;
}

export default function OfferAddressInput({
	onChange,
	value,
	label,
	required,
	error,
}: IOrganizationData) {
	const [address, setAddress] = useState("");

	const organizationSelected = useSelector<OrganizationInterface>(
		//@ts-ignore
		(state) => state.form.organizationSelected
	) as OrganizationInterface;

	useEffect(() => {
		setAddress(value);
	}, [value]);
	//------------------------------------------------------ google maps

	const mapApiJs = "https://maps.googleapis.com/maps/api/js";
	// @ts-ignore
	const options = () => {
		return {
			componentRestrictions: { country: organizationSelected.organizationCountry.toLowerCase() },
		};
	};

	const offerSearchInput = useRef(null);

	// init gmap script
	const initMapScript = () => {
		// if script already loaded
		if (window.google) {
			return Promise.resolve();
		}
		const src = `${mapApiJs}?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&v=weekly`;
		return googleAPI.loadAsyncScript(src);
	};
	// do something on address change
	// @ts-ignore
	const onChangeAddress = (autocomplete) => {
		const place = autocomplete.getPlace();
		const newAddress = googleAPI.extractAddress(place);
		const { place_id, plain, ...detailedRest } = newAddress;
		onChange(newAddress.plain() || "", detailedRest || {});
		setAddress(newAddress.plain() || "");
	};

	// init autocomplete
	const initAutocomplete = useCallback(() => {
		// if (!offerSearchInput.current) return;
		const input = document.getElementById("nf-address1");
		const autocomplete = new window.google.maps.places.Autocomplete(
			input as HTMLInputElement,
			options()
		);
		autocomplete.setFields(["address_component", "geometry"]);
		autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));
	}, [offerSearchInput]);

	// load map script after mounted
	useEffect(() => {
		initMapScript().then(() => initAutocomplete());
	}, [initAutocomplete]);
	//------------------------------------------------------

	return (
		<Stack spacing={4}>
			<Text size={14} fw={500} color="var(--heading)">
				{label}{" "}
				{required && (
					<span
						style={{
							color: "var(--error)",
						}}
					>
						*
					</span>
				)}
			</Text>
			<div>
				<input
					value={address}
					className="input-styles"
					style={{
						width: "100%",
						border: "1px solid var(--divider)",
						paddingLeft: "10px",
						borderColor: error ? "var(--error)" : "var(--divider)",
						color: error ? "var(--error)" : "var(--body)",
					}}
					placeholder=""
					onChange={(e) => {
						const newValue = e.target.value;
						setAddress(newValue);
						if (newValue === "") {
							onChange("", {});
						}
					}}
					ref={offerSearchInput}
					type="text"
					id="nf-address1"
					name="nf-address1"
				/>
				{error ? (
					<Text size={12} color="red">
						{error}
					</Text>
				) : null}
			</div>
		</Stack>
	);
}
