import { Badge, Divider, Flex, Stack, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconMessage } from "@tabler/icons";
import { ICampaignInterface } from "@views/clients/PromotionList";
import CardWrapper from "@views/pages/settingsV2/components/CardWrapper";
import moment from "moment";
import { useTranslation } from "react-i18next";

const returnStatus = (t: any, isExpired: boolean) => {
	if (isExpired) {
		return {
			color: "var(--bodyLight)",
			label: t("clientsV2.clientsDrawer.campaignStatus.expired"),
		};
	}
	return {
		color: "var(--accent)",
		label: t("clientsV2.clientsDrawer.campaignStatus.active"),
	};
};

interface ICampaignCardProps {
	campaign: ICampaignInterface;
	isInModal?: boolean;
}

export default function CampaignCard({ campaign, isInModal }: ICampaignCardProps) {
	const { t } = useTranslation();
	const isExpired = moment().isAfter(campaign.lastDayOfCampaign);
	const matchesXs = useMediaQuery("(max-width: 586px)");

	const borderStyle = {
		styles: {
			border: "1px solid var(--divider40)",
			padding: "16px 0",
			boxShadow: "none",
		},
		sh: "none",
	};

	return (
		<CardWrapper
			p="0px"
			sh="none"
			styles={{
				padding: "16px 0",
				boxShadow: "none",
			}}
			{...(isInModal && borderStyle)}
		>
			<Stack spacing={16}>
				<Flex px={isInModal ? 16 : 24} justify="space-between">
					<Flex align="center" gap={isInModal ? 8 : 16}>
						{!isInModal && (
							<div
								style={{
									padding: 8,
									backgroundColor: "var(--divider40)",
									borderRadius: 8,
									height: "fit-content",
								}}
							>
								<IconMessage color={isExpired ? "var(--grey)" : "var(--accent)"} size={32} />
							</div>
						)}
						<Stack spacing={0}>
							<Flex
								gap={4}
								direction={matchesXs ? "column" : "row"}
								align={matchesXs ? "flex-start" : "center"}
							>
								<Text color="var(--heading)" size={16} fw={600}>
									{campaign.campaignName}{" "}
								</Text>
								<Text color="var(--grey)" tt="uppercase" size={14} fw={500}>
									•{" "}
									{t("clientsV2.clientsDrawer.messagesSent", {
										count: campaign.phoneNumberListLength || 0,
									})}
								</Text>
							</Flex>
							<Text color="var(--bodyLight)" size={16}>
								{moment(campaign.createdAt).format("DD MMM YYYY")} -{" "}
								{moment(campaign.lastDayOfCampaign).format("DD MMM YYYY")}
							</Text>
						</Stack>
					</Flex>
					<Badge
						leftSection={
							<div
								style={{
									borderRadius: "1000px",
									width: 12,
									height: 12,
									backgroundColor: returnStatus(t, isExpired).color || "var(--heading)",
								}}
							/>
						}
						bg="none"
						px="10px"
						py="14px"
						radius={8}
						style={{
							border: "1px solid var(--divider)",
							fontSize: 14,
							textTransform: "none",
							fontWeight: 500,
							color: "var(--heading)",
						}}
					>
						<Text>{returnStatus(t, isExpired).label}</Text>
					</Badge>
				</Flex>
				<Divider color={isInModal ? "var(--divider40)" : "var(--divider)"} />
				<Text px={isInModal ? 16 : 24} size={14} color="var(--body)">
					{campaign.textSmsMessage}
				</Text>
			</Stack>
		</CardWrapper>
	);
}
