import { createStyles } from "@mantine/core";
import { CSSProperties, ReactNode } from "react";

interface ICardWrapperProps {
	p?: string;
	borderRadius?: string;
	children: ReactNode;
	w?: string;
	bg?: string;
	sh?: string;
	styles?: CSSProperties;
	id?: string;
	isSimple?: boolean
}

const useStyles = createStyles(() => ({
	cardContainer: {
		borderRadius: "20px",
		boxShadow: "var(--sh)",
		WebkitBoxShadow: "var(--sh)",
		padding: "24px",
	},
}));

export default function CardWrapper({
	p,
	borderRadius,
	children,
	w,
	bg = "var(--elevation)",
	sh,
	styles,
	id,
	isSimple
}: ICardWrapperProps) {
	const { classes } = useStyles();
	return (
		<div
			id={id}
			{...( !isSimple && {className: classes.cardContainer})}
			style={{
				padding: p,
				borderRadius: borderRadius,
				width: w,
				backgroundColor: bg,
				boxShadow: `${sh} !important`,
				WebkitBoxShadow: `${sh} !important`,
				...styles,
			}}
		>
			{children}
		</div>
	);
}
