import { Divider, Flex, Stack, Text, UnstyledButton } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons";
import OrderTypeBadge from "@views/clientsFeedback/components/OrderTypeBadge";
import localeNumber from "@views/horeca/v2/companyList/utils/localeNumber";
import CardWrapper from "@views/pages/settingsV2/components/CardWrapper";
import TitleAndDescription from "@views/smsNotifications/components/TitleAndDescription";
import moment from "moment";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationInterface } from "types";
import { ICarpetOrder } from "../../ordersHistory/interfaces/IOrder";
import returnOrderType from "../../ordersHistory/utils/returnOrderType";
import RowTitleAndDescripion from "../../readyForDelivery/components/RowTitleAndDescripion";
import OrderStatusBadge from "./OrderStatusBadge";

interface IOrderCardProps {
	order: ICarpetOrder;
	organization: OrganizationInterface;
}

export default function OrderCard({ order, organization }: IOrderCardProps) {
	const { t, i18n } = useTranslation();

	const returnTotalPrice = useCallback(() => {
		return order.restToMinimumOrder ? order.minimumOrder : order.totalPrice;
	}, [order]);

	return (
		<CardWrapper
			p="0px"
			sh="none"
			styles={{
				border: "1px solid var(--divider40)",
				paddingBlock: 16,
				boxShadow: "none",
			}}
		>
			<Stack spacing={8}>
				<Flex gap={16} justify="space-between" align="center" px={16}>
					<Stack spacing={0}>
						<UnstyledButton
							onClick={() => window.open(`#/covoare/edit/${order._id}`, "_blank")}
							className="link-style"
							w="fit-content"
						>
							<Flex gap={4}>
								<Text size={20} fw={600} color="var(--heading)">
									{t("ordersTable.newOrder.orderLabel")} #{order.ticketNumber || "N/A"}
								</Text>
								<IconExternalLink
									style={{
										marginTop: 6,
										flexShrink: 0,
									}}
									size={14}
								/>
							</Flex>
						</UnstyledButton>

						<Text size={14} color="var(--bodyLight)">
							{moment(order.createdAt).format("ddd, DD MMM YYYY, HH:mm")}
						</Text>
					</Stack>
					<OrderTypeBadge
						// @ts-ignore
						orderType={returnOrderType(t, order).value}
						pickpuPointName={order.pickupPointName}
					/>
				</Flex>
				<Divider color="var(--divider40)" />
				<RowTitleAndDescripion
					withoutBorder
					styles={{
						container: {
							paddingBlock: 0,
							paddingInline: 16,
						},
					}}
					description={order.clientName}
					title={t("ordersTable.nameOnOrder")}
				/>

				<Divider color="var(--divider40)" />
				<RowTitleAndDescripion
					styles={{
						container: {
							paddingBlock: 0,
							paddingInline: 16,
						},
					}}
					withoutBorder
					title={t("smsNotifications.table.columns.status")}
					description={<OrderStatusBadge order={order} />}
				/>
				<Divider color="var(--divider40)" />
				<RowTitleAndDescripion
					withoutBorder
					styles={{
						container: {
							paddingBlock: 0,
							paddingInline: 16,
						},
					}}
					description={`${order.summaryProductsTotalNumber}/${order.productsTotalNumber}`}
					title={t("ordersTable.scepticFaptic")}
				/>
				<Divider color="var(--divider40)" />
				<RowTitleAndDescripion
					withoutBorder
					styles={{
						container: {
							paddingBlock: 0,
							paddingInline: 16,
						},
					}}
					description={localeNumber({
						value: returnTotalPrice(),
						currency: organization.organizationCurrency,
						locale: i18n.language,
						style: "currency",
					})}
					title={t("ordersTable.totalPrice")}
				/>

				<Divider color="var(--divider40)" />
				<div
					style={{
						paddingInline: 16,
					}}
				>
					<TitleAndDescription
						reverse
						title={t("ordersTable.mentions")}
						description={order.notes || order.driverNotes || "-"}
					/>
				</div>
			</Stack>
		</CardWrapper>
	);
}
