import { createStyles, Flex, FlexProps, Tabs } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { ReactNode } from "react";
import TabsOptionsPopover from "./TabsOptionsPopover";

const useStyles = createStyles(() => ({
	tab: {
		color: "var(--body)",
		border: "none",
		borderRadius: 0,
		"&:hover": {
			backgroundColor: "var(--accentLight)",
		},
		"&[data-active]": {
			borderBottom: "3px solid var(--accent)",
			color: "var(--accent)",
			"&:hover": {
				borderBottom: "3px solid var(--accent)",
			},
		},
	},
	tabsList: {
		border: "none",
	},
	panel: {
		marginBottom: "24px",
		marginTop: "24px",
	},
	optionsContainer: {
		marginLeft: 8,
		borderLeft: "1px solid var(--divider40)",
		paddingLeft: 8,
	},
}));

export interface ITabProps {
	label: string;
	value: string;
	icon?: ReactNode;
	disabled?: boolean;
	rightSection?: ReactNode;
}

interface IPannelProps {
	component: ReactNode;
	value: string;
}

interface ICustomTabsProps {
	tabs: ITabProps[];
	pannels?: IPannelProps[];
	onTabChange: (tab: string | null) => void;
	activeTab: string | null;
	tabsLeftSection?: ReactNode;
	tabsRightSection?: ReactNode;
	flexStyles?: FlexProps;
	pageKey?: string;
	withTabOptions?: boolean;
	alwaysLabel?: boolean;
	fullWidth?: boolean;
}

export default function CustomTabs({
	tabs,
	pannels,
	activeTab,
	onTabChange,
	tabsLeftSection,
	tabsRightSection,
	flexStyles,
	pageKey,
	withTabOptions,
	alwaysLabel,
	fullWidth,
}: ICustomTabsProps) {
	const matches = useMediaQuery("(max-width: 768px)");
	const matchesXs = useMediaQuery("(max-width: 576px)");
	const { classes } = useStyles();
	return (
		<div
			style={{
				width: "100%",
			}}
		>
			<Tabs
				classNames={{
					tab: classes.tab,
					tabsList: classes.tabsList,
					panel: classes.panel,
				}}
				value={activeTab}
				onTabChange={(val) => onTabChange(val)}
			>
				<Tabs.List>
					<Flex
						direction={matches ? "column" : "row"}
						{...flexStyles}
						w="100%"
						align="center"
						justify="space-between"
						gap={16}
					>
						{tabsLeftSection}
						<Flex w={matchesXs || fullWidth ? "100%" : "auto"} align="center" gap={0}>
							{tabs.map((item, i) => (
								<Tabs.Tab
									w={matchesXs || fullWidth ? "100%" : "auto"}
									rightSection={item.rightSection}
									key={i}
									value={item.value}
									disabled={item.disabled}
									icon={item.icon}
								>
									{matchesXs && !alwaysLabel ? null : item.label}
								</Tabs.Tab>
							))}
							{withTabOptions && Boolean(pageKey) && (
								<div className={classes.optionsContainer}>
									<TabsOptionsPopover pageKey={pageKey} tabs={tabs} />
								</div>
							)}
						</Flex>
						{tabsRightSection}
					</Flex>
				</Tabs.List>
				{pannels?.map((item, i) => (
					<Tabs.Panel key={i} value={item.value}>
						{item.component}
					</Tabs.Panel>
				))}
			</Tabs>
		</div>
	);
}
