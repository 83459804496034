import { ActionIcon, Button, Checkbox, Flex, Popover, Stack, Text, Tooltip } from "@mantine/core";
import { IconLayoutColumns } from "@tabler/icons";
import { useTranslation } from "react-i18next";
import { IColumnModel } from "./DataTableWrapper";

interface IToggleColumnsButtonProps<TColumn> {
	columns: IColumnModel<TColumn>[];
	onChange: (newCols: string[]) => void;
	displayedColumns: string[];
	removeItem: () => void;
}

export default function ToggleColumnsButton<TColumn>({
	columns,
	onChange,
	displayedColumns,
	removeItem,
}: IToggleColumnsButtonProps<TColumn>) {
	const { t } = useTranslation();

	const changeColls = (col: string) => {
		const tempFiltered = columns
			.map((it) => it.key as string)
			.filter((column) => {
				if (displayedColumns.includes(column)) {
					return column !== col;
				} else {
					return column === col;
				}
			});
		onChange(tempFiltered);
	};

	const resetColumns = () => {
		removeItem();
		const tempColumns = columns.map((item) => item.key as string);
		onChange(tempColumns);
	};

	return (
		<Popover
			withArrow
			radius={8}
			position="bottom-start"
			styles={{
				dropdown: {
					// zIndex: 4000,
					boxShadow: "var(--sh)",
					padding: 24,
					// maxHeight: 400,
					// height: "100%"
				},
			}}
			withinPortal={true}
			zIndex={2000}
			width={250}
		>
			<Popover.Target>
				<Tooltip withArrow label={t("general.tableWrapper.tooltips.filterColumns")}>
					<ActionIcon variant="subtle">
						<IconLayoutColumns color="var(--heading)" />
					</ActionIcon>
				</Tooltip>
			</Popover.Target>
			<Popover.Dropdown>
				<Stack spacing={16}>
					<Stack spacing={4}>
						<Flex gap={16} justify="space-between" align="center">
							<Text size={24} fw={600}>
								{t("general.tableWrapper.tooltips.columnsPopover.title")}
							</Text>
							<Button
								onClick={resetColumns}
								variant="subtle"
								style={{
									padding: 0,
									width: "fit-content",
									background: "none",
									fontWeight: 400,
									textDecoration: "underline",
									color: "var(--bodyLight)",
								}}
							>
								{t("general.buttonReset")}
							</Button>
						</Flex>
						<Text size={14} color="var(--bodyLight)">
							{t("general.tableWrapper.tooltips.columnsPopover.description")}
						</Text>
					</Stack>

					<div
						style={{
							overflowY: "auto",
							height: "100%",
							maxHeight: 250,
						}}
					>
						<Stack spacing={16}>
							{columns
								.filter((col) => col.label && typeof col.label === "string")
								.map((col, i) => (
									<Checkbox
										onChange={() => changeColls(col.key as string)}
										checked={displayedColumns.includes(col.key as string)}
										color="yellow"
										key={i}
										label={col.label}
									/>
								))}
						</Stack>
					</div>
				</Stack>
			</Popover.Dropdown>
		</Popover>
	);
}
