import { Flex, Stack, Switch, TextInput, Tooltip } from "@mantine/core";
import { IconCheck, IconLink } from "@tabler/icons";
import Tip from "@views/pages/settingsV2/components/smsSettings/Tip";
import { Dispatch, SetStateAction } from "react";
import { INotificationData } from "./AddEditNotificationModal";

interface ILinkInputProps {
	notificationData: INotificationData;
	setNotificationData: Dispatch<SetStateAction<INotificationData>>;
}

export default function LinkInput({ notificationData, setNotificationData }: ILinkInputProps) {
	return (
		<Stack spacing={8}>
			<Flex align="flex-end" gap={16}>
				<TextInput
					w="100%"
					icon={<IconLink />}
					value={notificationData.link?.url}
					onChange={(e) => {
						// @ts-ignore
						setNotificationData((prev) => ({
							...prev,
							link: {
								...prev.link,
								url: e.target.value,
							},
						}));
					}}
					classNames={{
						input: "input-styles",
					}}
					label="Link"
					styles={{
						rightSection: {
							width: 80,
							marginBottom: 10,
						},
					}}
					rightSection={
						<div>
							<Tooltip withArrow label="Link extern">
								<div>
									<Switch
										checked={notificationData.link?.external}
										onChange={(e) =>
											// @ts-ignore
											setNotificationData((prev) => ({
												...prev,
												link: {
													...prev.link,
													external: e.target.checked,
												},
											}))
										}
										onLabel={<IconCheck size={16} />}
										color="yellow"
										size="md"
									/>
								</div>
							</Tooltip>
						</div>
					}
				/>
			</Flex>
			<Tip tipColor="var(--bodyLight)" tip="Totul dupa 'https://domain/#' pentru linkul intern." />
		</Stack>
	);
}
