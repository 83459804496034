import { Flex, Stack, Text, UnstyledButton } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons";
import { ICampaignInterface } from "@views/clients/PromotionList";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CampaignCard from "./CampaignCard";

interface ICampaignsSectionProps {
	campaigns: ICampaignInterface[];
}

export default function CampaignsSection({ campaigns }: ICampaignsSectionProps) {
	const { t, i18n } = useTranslation();
	moment.locale(i18n.language);
	return (
		<Stack spacing={16}>
			<UnstyledButton
				onClick={() => window.open(`/#/campanii/lista-campanii`, "_blank")}
				className="link-style"
				w="fit-content"
			>
				<Flex gap={4}>
					<Text size={20} fw={600} color="var(--heading)">
						{t("clientsV2.clientsDrawer.activeCampaigns")}
					</Text>
					<IconExternalLink
						style={{
							marginTop: 6,
						}}
						size={14}
					/>
				</Flex>
			</UnstyledButton>
			{campaigns.length ? (
				<Stack spacing={16}>
					{campaigns?.map((item, i) => (
						<CampaignCard key={i} isInModal campaign={item} />
					))}
				</Stack>
			) : (
				<Text ta="center" size={16} color="var(--bodyLight)">
					{t("clientsV2.clientsDrawer.campaignsNotFound")}
				</Text>
			)}
		</Stack>
	);
}
